import './App.css';

import React, {PureComponent} from 'react';
import Popup from "reactjs-popup";
import InfoSvg from "./img/info.svg";

export default class App extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      text: "loading",
      text2: null,
      pretext: ""
    };
    this.gpsPositionState = false;
    this.PopupOverlayStyle = {
      overflowY: "scroll",
    };
    this.PopupContentStyle = {
      width: "80%",
      position: "relative",
      background: "rgb(255, 255, 255)",
      margin: "auto",
      border: "1px solid rgb(187, 187, 187)",
      padding: "5px",
    };
  }

setText(date, rain, city) {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1)
  if (date.getDate() === today.getDate()) {
    this.setState({
      pretext: "",
      text: "heute"
    });
  } else if (date.getDate() === tomorrow.getDate()) {
    this.setState({
      pretext: "",
      text: "morgen"
    });
  } else {
    this.setState({
      pretext: "Am",
      text: date.toLocaleDateString('de-DE', { weekday: 'long' })
    });  
  }
  if (rain >= 5) {
    rain = rain.toLocaleString('de-DE', {  minimumFractionDigits: 0, maximumFractionDigits: 0});
  } else {
    rain = rain.toLocaleString('de-DE', {  minimumFractionDigits: 1, maximumFractionDigits: 1});
  }
  this.setState({
    text2: "wird es in " + city + " voraussichtlich " + rain + " Liter regnen."
  });
}

getWeather(lat,lon, gps) {
    fetch("https://api.openweathermap.org/data/2.5/forecast?lat="+lat+"&lon="+lon+"&units=metric&APPID=fcef67981c996dc619a2ae5164ea5704")
      .then(res => res.json())
      .then(
        (result) => {
          var set = false;
          let currentDate = null;
          let currentDayRain = 0.0;
          if (!gps && this.gpsPositionState) {
            return;
          }
          for (const period of result.list) {

            if ("rain" in period) {
              if (!currentDate) {
                currentDate = new Date(period.dt * 1000);
              }
              if (currentDate.getDate() !== new Date(period.dt * 1000).getDate()) {
                if (currentDayRain >= 1.0) {
                  this.setText(currentDate, currentDayRain, result.city.name);
                  set = true;
                  break;
                } else {
                  currentDate = new Date(period.dt * 1000);
                  currentDayRain = period["rain"]["3h"];
                }
              } else {
                currentDayRain += period["rain"]["3h"];
              }
            }
          }
          if (!set) {
            this.setState({
              pretext: "Die nächsten 5 Tage wird es in " + result.city.name + " voraussichtlich",
              text: "nie",
              text2: "regnen."
            });
          }
        },

        (error) => {
          this.setState({
            text: error
          });
        }
      )
}

gpsPosition = function(position) {
  this.gpsPositionState = true;
  this.getWeather(position.coords.latitude, position.coords.longitude, true);
  console.log("gps position");
}

gpsPositionError = function(error) {
  console.log(error);
}

componentDidMount() {
  fetch(
      "https://api.ipgeolocation.io/ipgeo?apiKey=7fe2f8875d154a8c9c1378ec0162fda3"
    )
      .then((response) => response.json())
      .then((data) => {
        if (!this.gpsPositionState) {
          this.getWeather(data.latitude, data.longitude, false);
          console.log("IP position");
        }
      });

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(this.gpsPosition.bind(this), this.gpsPositionError.bind(this));
  } else {
    alert("geolocation not supported");
  }
}


  render() {
    const { pretext, text, text2 } = this.state;
    return (
    <div className="App">
      <header className="App-header">
        <p className="App-small-text">
        {pretext}
        </p>
        <p className="App-huge-text">
        {text}
        </p>
        <p className="App-small-text">
        {text2}
        </p>
      </header>
      <Popup
          trigger={(open) => (
            <div className="rs-info" open={open}>
              {" "}
              <img src={InfoSvg} height={37} style={{position: "absolute",  bottom: "10px", right: "10px" }} alt="Info" />
            </div>
          )}
          closeOnDocumentClick
          overlayStyle={this.PopupOverlayStyle}
          contentStyle={this.PopupContentStyle}
          modal
        >
          <div>
            <h1>WannRegnet.Es</h1>
            
            <div className="impressum">
            <p>(Idee: Manuel Zündel)</p>
            <p>Du willst auch wissen wo .es etwas gibt? Schaue dir <a href="https://wogibt.es/">WoGibt.Es</a> an.</p>
              <h1>Impressum</h1>
              <p>Angaben gemäß § 5 TMG</p>
              <p>
                David Weber <br />
                Kunzenweg 25
                <br />
                79117 Freiburg im Breisgau <br />
              </p>
              <p>
                {" "}
                <strong>Vertreten durch: </strong>
                <br />
                David Weber
                <br />
              </p>
              <p>
                <strong>Kontakt:</strong> <br />
                Telefon: O17O - 888711O
                <br />
                E-Mail:
                &#100;&#97;&#118;&#105;&#100;&#119;&#101;&#98;&#101;&#114;&#97;&#112;&#112;&#115;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;
                <br />
              </p>
              <p>
                <strong>Haftungsausschluss: </strong>
                <br />
                <br />
                <strong>Haftung für Inhalte</strong>
                <br />
                <br />
                Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
                können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
                sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8
                bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die
                auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
                Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
                Kenntnis einer konkreten Rechtsverletzung möglich. Bei
                Bekanntwerden von entsprechenden Rechtsverletzungen werden wir
                diese Inhalte umgehend entfernen.
                <br />
                <br />
                <strong>Haftung für Links</strong>
                <br />
                <br />
                Unser Angebot enthält Links zu externen Webseiten Dritter, auf
                deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
                diese fremden Inhalte auch keine Gewähr übernehmen. Für die
                Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
                oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
                wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
                Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.
                <br />
                <br />
                <strong>Urheberrecht</strong>
                <br />
                <br />
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
                diesen Seiten unterliegen dem deutschen Urheberrecht. Die
                Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
                Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
                schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten,
                nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
                dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte
                Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
                Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
                werden wir derartige Inhalte umgehend entfernen.
                <br />
                <br />
                <strong>Datenschutz</strong>
                <br />
                <br />
                Die Nutzung unserer Webseite ist in der Regel ohne Angabe
                personenbezogener Daten möglich. Soweit auf unseren Seiten
                personenbezogene Daten (beispielsweise Name, Anschrift oder
                eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich,
                stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
                ausdrückliche Zustimmung nicht an Dritte weitergegeben. <br />
                Wir weisen darauf hin, dass die Datenübertragung im Internet
                (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
                aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff
                durch Dritte ist nicht möglich. <br />
                Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
                Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
                angeforderter Werbung und Informationsmaterialien wird hiermit
                ausdrücklich widersprochen. Die Betreiber der Seiten behalten
                sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                <br />
                <br />
                <br />
                <strong>Google Analytics</strong>
                <br />
                <br />
                Diese Website benutzt Google Analytics, einen Webanalysedienst
                der Google Inc. (''Google''). Google Analytics verwendet sog.
                ''Cookies'', Textdateien, die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglicht. Die durch den Cookie erzeugten Informationen über
                Ihre Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
                wird an einen Server von Google in den USA übertragen und dort
                gespeichert. Google wird diese Informationen benutzen, um Ihre
                Nutzung der Website auszuwerten, um Reports über die
                Websiteaktivitäten für die Websitebetreiber zusammenzustellen
                und um weitere mit der Websitenutzung und der Internetnutzung
                verbundene Dienstleistungen zu erbringen. Auch wird Google diese
                Informationen gegebenenfalls an Dritte übertragen, sofern dies
                gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
                Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
                IP-Adresse mit anderen Daten der Google in Verbindung bringen.
                Sie können die Installation der Cookies durch eine entsprechende
                Einstellung Ihrer Browser Software verhindern; wir weisen Sie
                jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
                sämtliche Funktionen dieser Website voll umfänglich nutzen
                können. Durch die Nutzung dieser Website erklären Sie sich mit
                der Bearbeitung der über Sie erhobenen Daten durch Google in der
                zuvor beschriebenen Art und Weise und zu dem zuvor benannten
                Zweck einverstanden.
                <br />
                <br />
                <strong>Google AdSense</strong>
                <br />
                <br />
                Diese Website benutzt Google Adsense, einen Webanzeigendienst
                der Google Inc., USA (''Google''). Google Adsense verwendet sog.
                ''Cookies'' (Textdateien), die auf Ihrem Computer gespeichert
                werden und die eine Analyse der Benutzung der Website durch Sie
                ermöglicht. Google Adsense verwendet auch sog. ''Web Beacons''
                (kleine unsichtbare Grafiken) zur Sammlung von Informationen.
                Durch die Verwendung des Web Beacons können einfache Aktionen
                wie der Besucherverkehr auf der Webseite aufgezeichnet und
                gesammelt werden. Die durch den Cookie und/oder Web Beacon
                erzeugten Informationen über Ihre Benutzung dieser Website
                (einschließlich Ihrer IP-Adresse) werden an einen Server von
                Google in den USA übertragen und dort gespeichert. Google wird
                diese Informationen benutzen, um Ihre Nutzung der Website im
                Hinblick auf die Anzeigen auszuwerten, um Reports über die
                Websiteaktivitäten und Anzeigen für die Websitebetreiber
                zusammenzustellen und um weitere mit der Websitenutzung und der
                Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
                wird Google diese Informationen gegebenenfalls an Dritte
                übertragen, sofern dies gesetzlich vorgeschrieben oder soweit
                Dritte diese Daten im Auftrag von Google verarbeiten. Google
                wird in keinem Fall Ihre IP-Adresse mit anderen Daten der Google
                in Verbindung bringen. Das Speichern von Cookies auf Ihrer
                Festplatte und die Anzeige von Web Beacons können Sie
                verhindern, indem Sie in Ihren Browser-Einstellungen ''keine
                Cookies akzeptieren'' wählen (Im MS Internet-Explorer unter
                ''Extras > Internetoptionen > Datenschutz > Einstellung''; im
                Firefox unter ''Extras > Einstellungen > Datenschutz >
                Cookies''); wir weisen Sie jedoch darauf hin, dass Sie in diesem
                Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
                voll umfänglich nutzen können. Durch die Nutzung dieser Website
                erklären Sie sich mit der Bearbeitung der über Sie erhobenen
                Daten durch Google in der zuvor beschriebenen Art und Weise und
                zu dem zuvor benannten Zweck einverstanden.
              </p>
              <br />
              Website Impressum erstellt durch{" "}
              <a href="https://www.impressum-generator.de">
                impressum-generator.de
              </a>{" "}
              von der{" "}
              <a href="https://www.kanzlei-hasselbach.de/">
                Kanzlei Hasselbach
              </a>
            </div>
          </div>
        </Popup>
    </div>

        
  );
  }
}
